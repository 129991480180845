import { BookingDisplay } from '@/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Direction, TDirection } from '@/data/direction';
import { formateTime } from '@/utils/formatTime';
import { formatDate } from '@/utils/formatDate';
import { getBookingListDisplay } from '@/api/getBookingListDisplay';
import { SignalRContext } from '@/providers/app';

interface BookingListBlockProps {
  bookables: { bookableId: number; direction: TDirection }[];
  shouldCollapse: boolean;
  hideEndTime: boolean;
  rowCount: number;
  timeThreshold: number;
  forcedTimeThreshold: number;
  hideName: boolean;
  hideBookableName: boolean;
  hideExtra: boolean;
  hideTime: boolean;
  hideLocation: boolean;
}

const ResponsiveText = ({ children, defaultFontSize }: any) => {
  const [fontSize, setFontSize] = useState(defaultFontSize);
  const textRef = useRef<any>(null);
  const containerRef = useRef<any>(null);

  const adjustFontSize = () => {
    if (!textRef.current || !containerRef.current) return;

    const containerWidth = containerRef.current.getBoundingClientRect().width;
    const containerHeight = containerRef.current.getBoundingClientRect().height;

    // Initial font size guess
    let fontSize = defaultFontSize;
    textRef.current.style.fontSize = `${fontSize}vh`;

    // Binary search algorithm for precise fitting
    let minSize = 0;
    let maxSize = fontSize;

    while (minSize < maxSize && Math.abs(maxSize - minSize) > 0.05) {
      // Precision threshold
      fontSize = (minSize + maxSize) / 2;
      textRef.current.style.fontSize = `${fontSize}vh`;

      const fitsHorizontally = textRef.current.scrollWidth <= containerWidth;
      const fitsVertically = textRef.current.scrollHeight <= containerHeight;

      if (fitsHorizontally && fitsVertically) {
        minSize = fontSize;
      } else {
        maxSize = fontSize;
      }
    }

    // Final adjustment
    fontSize = Math.max(minSize, 1); // Ensure minimum font size
    textRef.current.style.fontSize = `${fontSize}vh`;

    setFontSize(fontSize);
  };

  useEffect(() => {
    console.time('OriginalImplementation');
    adjustFontSize(); // Adjust font size on mount
    console.timeEnd('OptimizedImplementation');

    const handleResize = () => {
      requestAnimationFrame(adjustFontSize); // Adjust font size on window resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on unmount
    };
  }, [children]);

  return (
    <div ref={containerRef} className="w-full h-full leading-normal flex items-center justify-start">
      <span
        ref={textRef}
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          fontSize: `${fontSize}vh`,
        }}
      >
        {children}
      </span>
    </div>
  );
};

type BookingRow = BookingDisplay & { direction: TDirection | 'null' };

function BookingListPageItem({
  bookables = [],
  rowCount: _rowCount,
  shouldCollapse,
  hideName,
  hideBookableName,
  hideExtra,
  hideTime,
  hideLocation,
  hideEndTime,
  timeThreshold = 30,
  forcedTimeThreshold = 30,
}: BookingListBlockProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [bookings, setBookings] = useState<{ [key: number]: BookingDisplay[] }>([]);

  SignalRContext.useSignalREffect(
    'onBookingUpdated',
    (bookingItem: BookingDisplay) => {
      const bookable = bookings[bookingItem.bookableId];
      const booking = bookable.find(booking => booking.bookingItemId === bookingItem.bookingItemId);
      if (bookable && booking) {
        const index = bookable.indexOf(booking);
        bookable[index].startTime = bookingItem.startTime;
        bookable[index].endTime = bookingItem.endTime;
        bookable[index].name = bookingItem.name;
        bookable[index].location = bookingItem.location;
        bookable[index].extra = bookingItem.extra;
        bookable[index].imageUrl = bookingItem.imageUrl;
        setBookings({ ...bookings, [bookingItem.bookableId]: bookable });
      }
    },
    [bookings]
  );
  SignalRContext.useSignalREffect(
    'onRefetchBookings',
    () => {
      console.log('refetchBookings');
      getBookables();
    },
    [bookings]
  );
  SignalRContext.useSignalREffect(
    'onBookingRemoved',
    (bookingItemId: number) => {
      for (const key in bookings) {
        if (Object.prototype.hasOwnProperty.call(bookings, key)) {
          if (bookings[key].some(booking => booking.bookingItemId == bookingItemId)) {
            getBookables();
            break;
          }
        }
      }
    },
    [bookings]
  );
  async function getBookables() {
    try {
      const response = await getBookingListDisplay({
        bookableIds: bookables.map(({ bookableId }) => bookableId),
      });
      setBookings((response as any)?.data);
    } catch (error) {}
  }

  useEffect(() => {
    getBookables();

    // Refresh bookings every 24 hours
    const interval = setInterval(() => {
      getBookables();
    }, 30 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, []);

  const rowCount = shouldCollapse ? Object.entries(bookables).length : _rowCount ?? bookables.length;
  const rowHeight = containerRef.current?.clientHeight ? (containerRef.current.clientHeight / rowCount / containerRef.current.clientHeight) * 100 : 0;

  const rows = useMemo(() => {
    const timeThresholdMs = timeThreshold * 60 * 1000; // 30 minutes in milliseconds
    const forcedTimeThresholdMs = forcedTimeThreshold * 60 * 1000; // 30 minutes in milliseconds

    return bookables.reduce((acc: BookingRow[], { bookableId, direction }) => {
      const bookableBookings = bookings[bookableId] ?? [];

      const filteredBookings = bookableBookings
        .filter(({ startTime, endTime }) => {
          const startTimeMs = +new Date(formatDate(startTime.toString())); // Parse UTC start time
          const endTimeMs = +new Date(formatDate(endTime.toString())); // Parse UTC end time
          return startTimeMs - new Date().getTime() <= timeThresholdMs && endTimeMs - new Date().getTime() >= 0;
        })
        .sort((a, b) => +new Date(formatDate(a.startTime.toString())) - +new Date(formatDate(b.startTime.toString())))
        .map(booking => ({
          ...booking,
          startTime: new Date(formatDate(booking.startTime.toString())),
          endTime: new Date(formatDate(booking.endTime.toString())),
        }));

      const finalBookings = filteredBookings.reduce((result: BookingRow[], currentBooking) => {
        if (result.length === 0 || +new Date(currentBooking.startTime?.toString()) - +new Date(result[result.length - 1].startTime?.toString()) > forcedTimeThresholdMs) {
          result.push({ ...currentBooking, direction });
        } else {
          result[result.length - 1] = { ...currentBooking, direction };
        }
        return result;
      }, []);

      if (finalBookings.length > 0) {
        acc.push({ ...finalBookings[0], direction });
      }
      return acc;
    }, []);
  }, [bookings, bookables, timeThreshold, forcedTimeThreshold, currentTime]);

  return (
    <div ref={containerRef} className="booking-list-block h-full divide-y divide-input">
      {rows.map(row => (
        <div
          key={row.bookableId}
          style={{
            height: rowHeight + '%',
          }}
          className="booking-row"
        >
          <div className="flex w-full h-full justify-between items-center p-2">
            <div className="h-full w-full flex-col ">
              <div className="h-1/2 w-full flex items-center justify-between ">
                <div className={hideTime ? 'w-full ' : 'w-[65%] ' + 'h-full'}>
                  <ResponsiveText margin={10} defaultFontSize={rowHeight / 2}>
                    <span className="font-bold booking-name">{!hideName && row.name}</span>
                  </ResponsiveText>
                </div>
                {!hideTime && (
                  <div className="w-[30%]  h-full font-semibold ">
                    <ResponsiveText margin={10} defaultFontSize={rowHeight / 2}>
                      <span className="booking-time">
                        {row.startTime && formateTime(row.startTime.toString())}
                        {!hideEndTime && row.endTime && ` - ${formateTime(row.endTime.toString())}`}
                      </span>
                    </ResponsiveText>
                  </div>
                )}
              </div>
              <div className="h-1/2 w-full flex justify-start items-start">
                <div className="w-full h-full flex justify-between items-start  [&>div]:flex [&>div]:items-start  ">
                  <ResponsiveText margin={10} defaultFontSize={rowHeight / 2}>
                    <span className="font-semibold booking-name">
                      {!hideBookableName && <span className="booking-extra">{row.bookableName}</span>}
                      {!hideLocation && (
                        <>
                          {!hideBookableName && row.location && <span>, </span>}
                          <span className="booking-location">{row.location}</span>
                        </>
                      )}
                      {!hideExtra && (
                        <span className="booking-extra">
                          {(!hideBookableName || !hideLocation) && row.extra && <span> • </span>}
                          {row.extra}
                        </span>
                      )}
                    </span>
                  </ResponsiveText>
                </div>
              </div>
            </div>
            <div className="w-[5%] h-full  flex justify-end items-center ">
              <Direction direction={row.direction} className="text-[inherit] w-full h-full" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BookingListPageItem;
