import { useEffect, useRef, useState } from 'react';
import { Image } from '../../types';
import { ImageOff } from 'lucide-react';
import VideoBlock from './VideoPageItem';
import ImageBlock from './ImagePageItem';

interface CarouselBlockProps {
  media: Image[];
  backgroundColor: string;
  pageId: number;
}

const MediaComponent = (filename: string) => {
  const extension = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);

  switch (extension) {
    case 'mp4':
    case 'webm':
    case 'ogv':
    case 'mov':
      return VideoBlock;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'svg':
      return ImageBlock;
    default:
      return () => (
        <div className="w-full h-full bg-gray-200 grid place-items-center">
          <span className="text-gray-700 text-md font-semibold flex items-center gap-x-2">
            <ImageOff className="w-8 h-8" />
            Ingen media tillagd
          </span>
        </div>
      );
  }
};

function CarouselBlock(props: CarouselBlockProps) {
  const { media = [], backgroundColor, pageId } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [index, setIndex] = useState(0);

  const currentMedia = media[index];

  useEffect(() => {
    if (media.length === 0) {
      return;
    }

    const imageDuration = !isNaN(media[index].duration) ? media[index].duration : 5;

    const interval = setInterval(() => {
      setIndex(prev => (prev + 1) < media.length ? prev + 1 : 0);
    }, imageDuration * 1000);

    return () => clearInterval(interval);
  }, [media, index]);


  if (media.length === 0 || !currentMedia) {
    return (
      <div className="w-full h-full bg-gray-200 grid place-items-center">
        <span className="text-gray-700 text-md font-semibold flex items-center gap-x-2">
          <ImageOff className="w-8 h-8" />
          Inga bilder tillagda
        </span>
      </div>
    );
  }


  const Media = MediaComponent(currentMedia.src);

  return (
    <div
      ref={wrapperRef}
      className="relative w-full h-full duration-100 transition-opacity"
      style={{
        backgroundColor,
      }}
    >
      {media.map((_, i) => (
        <div
          id={`media_wrapper_${pageId.toString() + i}`}
          className={`
                    absolute top-0 left-0 w-full h-full 
                   ${i === index ? ' opacity-100  z-10' : ' opacity-0 z-0'}
                   ${' duration-1000 transition-opacity'}
                  `}
          key={`media_wrapper_${pageId.toString() + i}`}
        >
          <Media src={_.src} alt="" pageId={pageId} objectFit={_.objectFit as any} boundryId={`media_wrapper_${pageId.toString() + i}`} backgroundColor="transparent" />
        </div>
      ))}
    </div>
  );
}

export default CarouselBlock;
